<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
          <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
          <fade-render-transition>
            <card>
              <div>
                <h2>Forget Password</h2>
                <fg-input label="Email"
                          placeholder="Email"
                          name='email'
                          type='e-mail'
                          data-vv-as="Email"
                          v-validate="'required|email'"
                          :error="getError('email')"
                          v-model="form.email"
                          required>
                </fg-input>
              </div>
              <div class="float-right d-flex">
                <button class="btn btn-outline btn-round" v-on:click='$router.push("/login")'>Cancel</button>
                <button type="submit" class="btn btn-fill btn-primary btn-round ml-3" v-on:click='doLogin()'>Send Email</button>
              </div>
              
            </card>
          </fade-render-transition>
      </div>
    </div>
  </auth-layout>
</template>
<script>
  import { Checkbox as LCheckbox, FadeRenderTransition } from 'src/components/index'
  import AuthLayout from './AuthLayout.vue'
  import swal from 'sweetalert2/dist/sweetalert2.js'
  import lib from '../../../lib.js'
  export default {
    components: {
      LCheckbox,
      FadeRenderTransition,
      AuthLayout
    },
 data () {
      return {
        form: {
          email: ''
        },
        showAlert:false
      }
    },
    beforeMount (){
    },
    methods: {
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      doLogin(){
        var _this = this
        this.$validator.validateAll().then(async isValid => {
          if (!isValid) return
          
        })
      },
      closeMenu () {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      }
    },
    beforeDestroy () {
      this.closeMenu()
    }
  }
</script>
<style>
  .navbar-nav .nav-item p{
    line-height: inherit;
    margin-left: 5px;
  }
</style>
