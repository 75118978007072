<template>
  <div>
    <div v-show='isInHospitalListMode()'>

      <div class='d-flex justify-content-between mb-2'>
        <h6 class="my-auto"></h6>

        <div class="align-right my-auto">
          <button type="button" @click="handleAdd()" class="btn btn-round btn-large btn-primary my-auto">Add</button>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <el-table :data="pagedData" @row-click="handleRowChange" :cell-style="cellStyle">

            <el-table-column prop="id" label="No">
            </el-table-column>

            <el-table-column prop="name" label="Hospital Name">
            </el-table-column>

            <el-table-column prop="groupCount" label="Groups">
            </el-table-column>

            <el-table-column prop="createdAt" :formatter="getCreateDate" label="Created At">
            </el-table-column>

          </el-table>
        </div>
      </div>
      
      <!-- <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mt-3">
        <div class="">
          <p class="card-category"></p>
        </div>
        <l-pagination class="pagination-no-border"
          v-model="pagination.currentPage"
          :isMore="(PaginationToken != null)"
          :per-page="pagination.perPage"
          :total="pagination.total"
          @loadMore="getData(true)"/>
      </div> -->
    </div>

    <HospitalData :hospitalId='targetHospitalId' v-if='isInHospitalDataMode()' @back="backToHospitalList()" @edit="editHospital($event)" @browseGroup="browseGroup($event)" />
    <HospitalAdd v-if='isInHospitalAddMode()' @back="backToHospitalList()" />
    <HospitalEdit :hospital='targetHospital' v-if='isInHospitalEditMode()' @back="backToHospital()" />

    <GroupData :groupId='targetGroupId' v-if='isInGroupDataMode()' @back="backToHospital()" @edit="editGroup($event)" @groupMemberAdd="groupMemberAdd($event)" />
    <GroupEdit :group='targetGroup' v-if='isInGroupEditMode()' @back="backToGroup()" />
    <GroupMemberAdd :group='targetGroup' v-if='isInGroupMemberAddMode()' @back="backToGroup()" />

  </div>
</template>

<script>
 /* eslint-disable */ 
  import { Dialog, Table, TableColumn, MessageBox, Select, Option, DatePicker, Tag, Autocomplete } from 'element-ui'
  import { Pagination as LPagination, ImageUpload,  Switch as LSwitch } from 'src/components/index'
  import lib from '../../../lib'
  import tools from '../../../tools'
  import swal from 'sweetalert2/dist/sweetalert2.js'
  import phoneData from '../../../config/phone.js'
  import PhoneNumber from 'awesome-phonenumber'
  import Vue from "vue"
  import member from '../Components/Member'
  import PersonalData from '../Components/PersonalData'
  import HospitalAdd from '../Components/HospitalAdd'
  import HospitalData from '../Components/HospitalData'
  import HospitalEdit from '../Components/HospitalEdit'
  import GroupData from '../Components/GroupData'
  import GroupEdit from '../Components/GroupEdit'
  import GroupMemberAdd from '../Components/GroupMemberAdd'
  import moment from 'moment'

  export default {
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Dialog.name]: Dialog,
      [Table.name]: Table,
      [Autocomplete.name]: Autocomplete,
      [TableColumn.name]: TableColumn,
      [DatePicker.name]: DatePicker,
      [Tag.name]: Tag,
      LSwitch,
      LPagination,
      ImageUpload,
      member: member,
      PersonalData: PersonalData,
      HospitalAdd: HospitalAdd,
      HospitalData: HospitalData,
      HospitalEdit: HospitalEdit,
      GroupData: GroupData,
      GroupEdit: GroupEdit,
      GroupMemberAdd: GroupMemberAdd
    },
    data () {
      return {
        mode: Vue.Constants.HospitalPageMode.HospitalList,
        pagination: {
          perPage: 20,
          currentPage: 1,
          total: 0
        },
        PaginationToken: null,
        form:{
          id: null,
          image: null,
          title: null,
          content: null,
        },
        searchForm:{
        },
        query: {
          search_type: null,
          search_text: '',
          countryCode: null
        },
        is_edit_page: false,
        is_edit: false,
        is_new: false,
        newTag:'',
        preferred_username_1: null,
        preferred_username_2: null,
        phone_number_1: null,
        phone_number_2: null,
        tableData: [],
        companies: [],
        phoneData: [],
        tagData:[],
        tags: {
          dynamicTags: [{name:'Tag 1'},{name: 'Tag 2'},{name: 'Tag 3'}],
          // inputVisible: false,
          inputValue: ''
        },
        targetHospitalId: null,
        targetHospital: null,
        targetGroupId: null,
        targetGroup: null,
      }
    },
    async mounted () {
      await this.resetPage() 
    },
    watch:{
      // check_phone_number(value) {
      //   this.$validator.validate('check_phone_number', value);
      // }
    },
    created: function () {

      this.phoneData = phoneData
      this.$validator.extend('checkPhone', {
        getMessage: function (field) { 
          return '手機 格式有誤'; 
        },
        validate: function (value) {
          var pn = new PhoneNumber(value)
          return pn.isMobile()        
        }
      })

      this.$validator.extend('checkTwId', {
        getMessage: function (field) { 
          return '身分證 格式有誤'; 
        },
        validate: function (id) {
          let tab = "ABCDEFGHJKLMNPQRSTUVXYWZIO"                     
          let A1 = new Array (1,1,1,1,1,1,1,1,1,1,2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3)
          let A2 = new Array (0,1,2,3,4,5,6,7,8,9,0,1,2,3,4,5,6,7,8,9,0,1,2,3,4,5)
          let Mx = new Array (9,8,7,6,5,4,3,2,1,1);

          if ( id.length != 10 ) return false;
          let i = tab.indexOf( id.charAt(0) );
          if ( i == -1 ) return false;
          let sum = A1[i] + A2[i]*9;

          for (let j=1; j<10; j++ ) {
            let v = parseInt( id.charAt(j) );
            if ( isNaN(v) ) return false;
            sum = sum + v * Mx[j];
          }
          if ( sum % 10 != 0 ) return false;
          return true;
        }
      })

    },
    computed: {
      check_phone_number (){
        return `+${this.phone_number_1}${this.phone_number_2}`
      },
      getTitle (){
        return (!this.form.sub)?'新增會員':'編輯會員'
      },
      pagedData () {
        if (this.tableData) {
          return this.tableData.slice(this.from, this.to)
        } else {
          return null
        }
      },
      queriedData () {
        let result = this.tableData
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      },
      getPasswordRule(){
        let rule = {}
        if (!Boolean(this.form.sub)){
          rule.required = true
        }
        rule.regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
        return rule
      },
      getIdRule(){
        let rule = {}
        if (!Boolean(this.form.sub)){
          rule.required = true
        }
        if (this.preferred_username_2 == '1'){
          rule.checkTwId = true
        }
        console.log(rule)
        return rule
      },
    },
    methods: {
      cellStyle(row, rowIndex) {
        return 'cursor: pointer;'
      },
      backToHospitalList() {
        this.mode = Vue.Constants.HospitalPageMode.HospitalList
        this.targetHospitalId = null
        this.targetHospital = null

        // refresh page in case user is updated
        this.resetPage()
      },
      backToHospital() {
        this.mode = Vue.Constants.HospitalPageMode.HospitalData
      },
      backToGroup() {
        this.mode = Vue.Constants.HospitalPageMode.GroupData
        this.targetGroup = null
      },
      editHospital(hospital) {
        this.targetHospital = hospital
        this.mode = Vue.Constants.HospitalPageMode.HospitalEdit
      },
      browseGroup(groupId) {
        this.targetGroupId = groupId
        this.mode = Vue.Constants.HospitalPageMode.GroupData
      },
      editGroup(group) {
        this.targetGroup = group
        this.mode = Vue.Constants.HospitalPageMode.GroupEdit
      },
      groupMemberAdd(group) {
        this.targetGroup = group
        this.mode = Vue.Constants.HospitalPageMode.GroupMemberAdd
      },
      isInHospitalListMode() {
        return this.mode == Vue.Constants.HospitalPageMode.HospitalList
      },
      isInHospitalDataMode() {
        return this.mode == Vue.Constants.HospitalPageMode.HospitalData
      },
      isInHospitalAddMode() {
        return this.mode == Vue.Constants.HospitalPageMode.HospitalAdd
      },
      isInHospitalEditMode() {
        return this.mode == Vue.Constants.HospitalPageMode.HospitalEdit
      },
      isInGroupDataMode() {
        return this.mode == Vue.Constants.HospitalPageMode.GroupData
      },
      isInGroupEditMode() {
        return this.mode == Vue.Constants.HospitalPageMode.GroupEdit
      },
      isInGroupMemberAddMode() {
        return this.mode == Vue.Constants.HospitalPageMode.GroupMemberAdd
      },
      handleRowChange(row, event, column) {
        this.targetHospitalId = row.id
        this.mode = Vue.Constants.HospitalPageMode.HospitalData
      },
      getCreateDate(row){
        var date1 = new Date(row.createdAt)
        return moment(date1).format('YYYY-MM-DD')
      },
      getCountryCodeForRegionCode: PhoneNumber.getCountryCodeForRegionCode,
      querySearch(query, cb){
        let data = this.tagData.filter(el => el.name.search(query) >= 0)
        if (this.form.tags){
          let names = (this.form.tags.map(({ name }) => name))
          data = data.filter(({ name }) => !names.find(el => el == name))
        }
        let output = []
        data.map(el => output.push({id: el.id, value: el.name}))
        cb(output)
      },
      handleSelect(item) {
        if (!this.form.tags) this.$set(this.form, 'tags', [])
        this.form.tags.push({id:item.id, name: item.value})
        this.tags.inputValue = ''
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      getStatusText(row) {
        return (row.status) ? 'ON' : 'OFF'
      },
      getPhoneNumber(row){
        var phone_number = row.phone_number
        var pn = new PhoneNumber(phone_number)
        return '+' + pn.getCountryCode() + '  ' + pn.getNumber( 'significant' )
      },
      getSubmitText(){
        return (!this.form.sub)?'確定新增':'儲存'
      },
      handleInputConfirm () {
        let inputValue = this.tags.inputValue
        if (inputValue) {
          if (!this.form.tags) this.$set(this.form, 'tags', [])
          if (!this.form.tags.find(el => el.name == inputValue)){
              this.form.tags.push({name: inputValue})
          }else{
             swal('重複的標籤', '', 'error')
          }
        }
        this.tags.inputVisible = false
        this.tags.inputValue = ''
      },
      handleClose (tag) {
       this.form.tags.splice(tag, 1)
        // this.tags.dynamicTags.splice(this.tags.dynamicTags.indexOf(tag), 1)
        // this.form.tags.splice(idx, 1)
        // console.log(JSON.parse(JSON.stringify(this.form.tags)))
      },
      validate () {
        var _this = this
        this.$validator.validateAll().then(isValid => {
          console.log(  this.$validator)
          if (!isValid) return;
          if (!this.form.sub){
            _this.create()
          } else{
            _this.update()
          }
        })
      },
      handleImageUpload(result, data){
        if (result){
          this.form.image = data
        }else{
          swal('上傳失敗', data, 'error')
        }
      },
      edit (){
        this.is_edit = true
      },
      editCancel (){
        this.is_edit = false
        if (this.origin) this.form = JSON.parse(JSON.stringify(this.origin))
      },
      handleChange(){
        if (this.query.search_type == 'phone_number') this.query.countryCode = "886"
        this.query.search_text = ''
      },
      async resetPage() {
          await this.getData()
      },
      async handleIndexChange() {
        await this.getData()
      },
      handleAdd() {
        this.mode = Vue.Constants.HospitalPageMode.HospitalAdd
      },
      async handleNew () {
        await this.clearForm()
        this.is_edit_page = true
        this.is_edit = true
        this.form = {"Enabled": true}
        this.phone_number_1 = "886"
        this.phone_number_2 = null
        this.preferred_username_1 = null
        this.preferred_username_2 = null
        this.$validator.errors.clear()
      },
      async handleEdit (row) {
        await this.clearForm()
        this.is_edit_page = true
        this.$validator.errors.clear()
        
        this.origin = JSON.parse(JSON.stringify(row))
        this.form = JSON.parse(JSON.stringify(row))
        var phone_number = row.phone_number
        var pn = new PhoneNumber(phone_number)
        this.phone_number_1 = pn.getCountryCode().toString()
        this.phone_number_2 = pn.getNumber( 'significant' )
      },
      async getData() {
        let query = {}
        query.page = this.pagination.currentPage - 1
        var result = await lib.getHospitalList(query)
        if (result.status == Vue.Constants.HttpStatus.Success) {
          var data = result.data
          this.tableData = data.data
        }
      },
      async clearForm(){
        var _this = this
        return new Promise(function(resolve, reject){  
            _this.form.sub = ''
          resolve()
        })
      },
      async create(){
        this.form.preferred_username = `${this.preferred_username_1}-${this.preferred_username_2}`
        this.form.phone_number = `+${this.phone_number_1}${this.phone_number_2}`
        var result = await lib.createMember(this.form);
        if (result.code == 0){
          await this.getData()
          this.is_edit = false
          this.is_edit_page = false
          swal('新增成功', '', 'success')
        }else {
          let msg = '新增失敗'
          if (result.code == 3) msg = '身份重複'
          if (result.data){
            if (result.data.code == 'UsernameExistsException') msg = '此手機已註冊過'
             swal(msg, '', 'error')
             return
          }
          swal(msg, JSON.stringify(result.data), 'error')
        }
      },
      async update(){
        var result = await lib.updateMember(this.form.sub, this.form);
        if (result.code == 0){
          await this.getData()
          this.is_edit = false
          this.is_edit_page = false
          swal('已更新', '', 'success')
        }else {
          swal('更新失敗', '', 'error')
        }
      },
      async handleDelete(id, porp){
        var confirm = await tools.confirmDialog("請確認要刪除?");
          if (confirm){
            var result = await lib.deleteUser(id)
            if (result.code == 0){
              this.pagination.currentPage = 1
              this.is_edit = false
              this.is_edit_page = false
              await this.getData()
              swal('已刪除', '', 'success')
            }else{
              swal('刪除失敗', '', 'error')
            }
        }
      }
    }
  }
</script>
<style>
.toolsBar{
  justify-content: space-between;
  display: flex;
}

.leftTools{
  display: flex;
}

.leftTools > *{
  padding-right: 10px;
}
</style>
