<template>
  <div>
    <card>
      <vue-tabs value="Description">
        <template v-for="(i, idx) in subMenu">
        <v-tab v-bind:title='i' v-bind:key="idx">
        <div class='row d-flex justify-content-between align-items-center' style='margin:20px 20px 0px'>
          <div style='font-size:20px'>密碼修改</div>
          <div >
            <button type="submit" @click.prevent="validate" class="btn btn-fill btn-info align-right" style="magrin-top:10px;">儲存</button>
          </div>
        </div>
          <br/>
          
          <fg-input label="舊密碼"
                type="password"
                name="password"
                data-vv-as="舊密碼"
                class='rows'
                style='padding-top:6px;'
                v-model="form.password"
                v-validate="{
                  required:true
                }"
                :error="getError('password')"
                required>
          </fg-input>
          <br/>
          <fg-input label="新密碼"
                type="password"
                name="new_password"
                ref="new_password"
                placeholder="請輸入數字與英文，最少8字元"
                data-vv-as="新密碼"
                class=''
                style='padding-top:6px;'
                v-model="form.new_password"
                v-validate="{
                  required:true,
                  regex: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
                }"
                :error="getError('new_password')"
                required>
          </fg-input>
          <fg-input label="確認新密碼"
                type="password"
                name="confirm_password"
                placeholder="請輸入數字與英文，最少8字元"
                data-vv-as="確認新密碼"
                class=''
                style='padding-top:6px;'
                v-model="form.confirm_password"
                v-validate="{
                  required: true,
                  confirmed: 'new_password'
                }"
                :error="getError('confirm_password')"
                required>
          </fg-input>
        </v-tab>
        </template>
      </vue-tabs>
    </card>
  </div>
</template>
<script>
 /* eslint-disable */ 
  import { Dialog, Table, TableColumn, MessageBox, Select, Option } from 'element-ui'
  import lib from '../../../lib'
  import tools from '../../../tools'
  import swal from 'sweetalert2/dist/sweetalert2.js'

  export default {
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Dialog.name]: Dialog,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
    },
    data () {
      return {
        subMenu:['密碼修改'],
        pagination: {
          perPage: 20,
          currentPage: 1,
          total: 0,
          maxLimit: 10
        },
        form:{
          password: null,
          new_password: null,
          confirm_password: null
        },
        searchForm:{
        },
        query: {},
        modals: {
          custom: false
        },
        imageUrl: null,
        tableData: []
      }
    },
   async mounted () {
    },
    watch:{
    },
    computed: {
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      queriedData () {
        let result = this.tableData
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      }
    },
    methods: {
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      validate () {
        var _this = this
        this.$validator.validateAll().then(isValid => {
          if (!isValid) return;
          _this.update()
        })
      },
      async update(){
        var result = await lib.updateMe(this.form);
        if (result.code == 0){
          swal('已更新', '', 'success')
        }else {
          swal('更新失敗', result.data, 'error')
        }
      }
    }
  }
</script>
<style>
.toolsBar{
  justify-content: space-between;
  display: flex;
}

.leftTools{
  display: flex;
}

.leftTools > *{
  padding-right: 10px;
}
</style>
