var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('card',[_c('vue-tabs',{attrs:{"value":"Description"}},[_vm._l((_vm.subMenu),function(i,idx){return [_c('v-tab',{key:idx,attrs:{"title":i}},[_c('div',{staticClass:"row d-flex justify-content-between align-items-center",staticStyle:{"margin":"20px 20px 0px"}},[_c('div',{staticStyle:{"font-size":"20px"}},[_vm._v("密碼修改")]),_c('div',[_c('button',{staticClass:"btn btn-fill btn-info align-right",staticStyle:{"magrin-top":"10px"},attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_vm._v("儲存")])])]),_c('br'),_c('fg-input',{directives:[{name:"validate",rawName:"v-validate",value:({
                required:true
              }),expression:"{\n                required:true\n              }"}],staticClass:"rows",staticStyle:{"padding-top":"6px"},attrs:{"label":"舊密碼","type":"password","name":"password","data-vv-as":"舊密碼","error":_vm.getError('password'),"required":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('br'),_c('fg-input',{directives:[{name:"validate",rawName:"v-validate",value:({
                required:true,
                regex: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
              }),expression:"{\n                required:true,\n                regex: /^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{8,}$/\n              }"}],ref:"new_password",refInFor:true,staticStyle:{"padding-top":"6px"},attrs:{"label":"新密碼","type":"password","name":"new_password","placeholder":"請輸入數字與英文，最少8字元","data-vv-as":"新密碼","error":_vm.getError('new_password'),"required":""},model:{value:(_vm.form.new_password),callback:function ($$v) {_vm.$set(_vm.form, "new_password", $$v)},expression:"form.new_password"}}),_c('fg-input',{directives:[{name:"validate",rawName:"v-validate",value:({
                required: true,
                confirmed: 'new_password'
              }),expression:"{\n                required: true,\n                confirmed: 'new_password'\n              }"}],staticStyle:{"padding-top":"6px"},attrs:{"label":"確認新密碼","type":"password","name":"confirm_password","placeholder":"請輸入數字與英文，最少8字元","data-vv-as":"確認新密碼","error":_vm.getError('confirm_password'),"required":""},model:{value:(_vm.form.confirm_password),callback:function ($$v) {_vm.$set(_vm.form, "confirm_password", $$v)},expression:"form.confirm_password"}})],1)]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }