<template>
  <div>
    <!-- <card>
    <vue-tabs @tab-change="handleTabChange">
      <v-tab title='會員資訊' name='member' >
        <member ref="member"  />
      </v-tab>
    </vue-tabs>
    </card> -->

    <div v-show='isInGroupListMode()'>
      <div class='d-flex justify-content-between mb-2'>
        <h6 class="my-auto">Group List</h6>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <!-- highlight-current-row
          @current-change="selectionRowsChange" -->
          <el-table :data="groupList" @row-click="handleRowChange" :cell-style="cellStyle">

            <el-table-column prop="name" label="Group">
            </el-table-column>

            <el-table-column prop="dataSharingRate" :formatter="getDataSharingRateText" label="Data Sharing">
            </el-table-column>

            <el-table-column prop="memberCount" label="Members">
            </el-table-column>

            <!-- <el-table-column prop="status" :formatter="getStatusText" label="Status">
            </el-table-column> -->

          </el-table>
        </div>
      </div>
      
      <!-- <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mt-3">
        <div class="">
          <p class="card-category"></p>
        </div>
        <l-pagination class="pagination-no-border"
          v-model="pagination.currentPage"
          :isMore="(PaginationToken != null)"
          :per-page="pagination.perPage"
          :total="pagination.total"
          @input='handleIndexChange'
          @loadMore="getData()"/>
      </div> -->
    </div>

    <GroupData :groupId='targetGroupId' v-if='isInGroupDataMode()' @back="backToGroupList()" @edit="editGroup($event)" @groupMemberAdd="groupMemberAdd($event)" />
    <GroupEdit :group='targetGroup' v-if='isInGroupEditMode()' @back="backToGroup()" />
    <GroupMemberAdd :group='targetGroup' v-if='isInGroupMemberAddMode()' @back="backToGroup()" />
  </div>
</template>


<script>
 /* eslint-disable */ 

  import { Dialog, Table, TableColumn, MessageBox, Select, Option, DatePicker, Tag, Autocomplete } from 'element-ui'
  import { Pagination as LPagination, ImageUpload,  Switch as LSwitch } from 'src/components/index'
  import tools from '../../../tools'
  import swal from 'sweetalert2/dist/sweetalert2.js'
  import Vue from "vue"
  import GroupData from '../Components/GroupData'
  import GroupEdit from '../Components/GroupEdit'
  import GroupMemberAdd from '../Components/GroupMemberAdd'
  import lib from '../../../lib'


export default {
  data () {
    return {
      // currentPage : 'user',
      mode: Vue.Constants.GroupPageMode.GroupList,
      pagination: {
        perPage: 15,
        currentPage: 1,
        total: 0
      },
      PaginationToken: null,
      groupList: null,
      targetGroupId: null,
      targetGroup: null
    }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [Autocomplete.name]: Autocomplete,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Tag.name]: Tag,
    LSwitch,
    LPagination,
    ImageUpload,
    GroupData: GroupData,
    GroupEdit: GroupEdit,
    GroupMemberAdd: GroupMemberAdd
  },
  async mounted () {
    // await this.getHospitalList()
    await this.getGroupList()
  },
  methods:{
    cellStyle(row, rowIndex) {
      return 'cursor: pointer;'
    },
    async getGroupList() {
      var result = await lib.listGroup()
      if (result.status == Vue.Constants.HttpStatus.Success) {
        var data = result.data
        this.groupList = data.data
      }
    },
    isInGroupListMode() {
      return this.mode == Vue.Constants.GroupPageMode.GroupList
    },
    isInGroupDataMode() {
      return this.mode == Vue.Constants.GroupPageMode.GroupData
    },
    isInGroupEditMode() {
      return this.mode == Vue.Constants.GroupPageMode.GroupEdit
    },
    isInGroupMemberAddMode() {
      return this.mode == Vue.Constants.GroupPageMode.GroupMemberAdd
    },
    async backToGroupList() {
      this.mode = Vue.Constants.GroupPageMode.GroupList
      this.targetGroupId = null

      await this.getGroupList()
    },
    backToGroup() {
      this.mode = Vue.Constants.GroupPageMode.GroupData
    },
    editGroup(group) {
      this.targetGroup = group
      this.mode = Vue.Constants.GroupPageMode.GroupEdit
    },
    groupMemberAdd(group) {
      this.targetGroup = group
      this.mode = Vue.Constants.GroupPageMode.GroupMemberAdd
    },
    handleRowChange(row, event, column) {
      this.targetGroupId = row.id
      this.mode = Vue.Constants.GroupPageMode.GroupData
    },
    getDataSharingRateText(value) {
      return value.dataSharingRate.toString() + "%"
    },
  }
}
</script>
