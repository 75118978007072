import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue'
// Dashboard pages
import Overview from 'src/pages/Dashboard/Dashboard/Overview.vue'
// import Stats from 'src/pages/Dashboard/Dashboard/Stats.vue'

// Pages
// import User from 'src/pages/Dashboard/Pages/UserProfile.vue'
// import TimeLine from 'src/pages/Dashboard/Pages/TimeLinePage.vue'
import Login from 'src/pages/Dashboard/Pages/Login.vue'
import ForgetPassword from 'src/pages/Dashboard/Pages/ForgetPassword.vue'

import Setting from 'src/pages/Dashboard/Pages/Setting.vue'
import General from 'src/pages/Dashboard/Pages/General.vue'
import Activity from 'src/pages/Dashboard/Pages/Activity.vue'

import OrganizationPage from 'src/pages/Dashboard/Pages/Organization.vue'
import Member from 'src/pages/Dashboard/Pages/Member.vue'
import Hospital from 'src/pages/Dashboard/Pages/Hospital.vue'
import Group from 'src/pages/Dashboard/Pages/Group.vue'

import PersonalData from 'src/pages/Dashboard/Pages/PersonalData.vue'
import ResetPassword from 'src/pages/Dashboard/Pages/ResetPassword.vue'

import DatasetDownload from 'src/pages/Dashboard/Pages/DatasetDownload.vue'
import DatasetOverview from 'src/pages/Dashboard/Pages/DatasetOverview.vue'

import CaseParameter from 'src/pages/Dashboard/Pages/CaseParameter.vue'
    

const routes = [
  {
    path: '/',
    redirect: '/overview'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/forgetpassword',
    name: 'ForgetPassword',
    component: ForgetPassword
  },
  {
    path: '',
    component: DashboardLayout,
    // redirect: '/admin/overview',
    // redirect: '/admin/items',
    children: [
  {
    path: '/overview',
    name: 'Overview',
    component: Overview
  },
  {
    path: '/settings',
    name: 'Setting',
    component: Setting
  },
  {
    path: '/general',
    name: 'General',
    component: General
  },
  {
    path: '/activity',
    name: 'Activity',
    component: Activity
  },
  {
    path: '/organization',
    name: 'OrganizationPage',
    component: OrganizationPage
  },
  {
    path: '/member',
    name: 'Member',
    component: Member
  },
  {
    path: '/hospital',
    name: 'Hospital',
    component: Hospital
  },
  {
    path: '/group',
    name: 'Group',
    component: Group
  },
  {
    path: '/dataset-overview',
    name: 'DatasetOverview',
    component: DatasetOverview
  },
  {
    path: '/dataset-download',
    name: 'DatasetDownload',
    component: DatasetDownload
  },
  {
    path: '/case-parameter',
    name: 'CaseParameter',
    component: CaseParameter
  },
  {
    path: '/personaldata',
    name: 'PersonalData',
    component: PersonalData
  },
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    component: ResetPassword
  }
  
  
  
]
  },
  {path: '*', component: NotFound}
]

export default routes
