import swal from 'sweetalert2/dist/sweetalert2.js'

const confirmDialog = async function (title){
  return swal.fire({
    title: title,
    type: 'question',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
    cancelButtonText: 'No'
    }).then((result) => {
      return (result.value)?true:false
    })
}

const checkTabs = (that) => {
  var errorTab = []
  that.errors.items.forEach(e => {
    errorTab.push(e.field.split('.')[0]) 
  });

  var errorTab = Array.from(new Set(errorTab))
  var errorText = []
  errorTab.forEach(e => {
    if (that.form.translations[e]){
      errorText.push(that.getLanguageText(that.form.translations[parseInt(e)].language_id))
    }
  });
  if (that.form.translations.length > 1 && errorTab.length > 0){
    swal('請檢查以下頁籤', errorText.join('<br>'), 'info')
  }
}

export default {
  confirmDialog: confirmDialog,
  checkTabs: checkTabs
}
