<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <fg-input label="Old Password"
                  type="password"
                  name="oldPassword"
                  data-vv-as="Old Password"
                  v-model="form.oldPassword"   
                  v-validate="{ required: true }"
                  :error="getError('oldPassword')"
                  @keyup.enter="validate()"/>
        <fg-input label="New Password"
                  type="password"
                  name="newPassword"
                  data-vv-as="New Password"
                  v-model="form.newPassword"
                  v-validate="getPasswordRule"
                  :error="getError('newPassword')"
                  @keyup.enter="validate()"/>
        <fg-input label="Comfirm New Password"
                  type="password"
                  name="comfirmNewPassword"
                  data-vv-as="Comfirm New Password"
                  v-model="form.comfirmNewPassword"   
                  v-validate="getPasswordRule"
                  :error="getError('comfirmNewPassword')"
                  @keyup.enter="validate()"/>
      </div>
    </div>
    <div class='row'>
      <div class="col-md-6">
        <div class="float-right">
          <button type="submit" @click.prevent="validate" class="btn btn-fill btn-primary">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Checkbox as LCheckbox, FadeRenderTransition } from 'src/components/index'
  import sweetAlert from 'sweetalert2/dist/sweetalert2.js'
  import lib from '../../../lib.js'
  import Vue from "vue"

  export default {
    components: {
      LCheckbox,
      FadeRenderTransition
    },
    data () {
      return {
        form: {
          oldPassword: '',
          newPassword: '',
          comfirmNewPassword: ''
        },
        showAlert:false
      }
    },
    beforeMount (){
    },
    computed: {
      getPasswordRule(){
        let rule = {}
        rule.required = true
        rule.regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
        return rule
      }
    },
    methods: {
      async updateMe() {
        const _this = this
        const result = await lib.updateMe({
          oldPassword: this.form.oldPassword,
          password: this.form.newPassword
        })

        if (result.status == Vue.Constants.HttpStatus.Success) {
          sweetAlert.fire({
            type: 'success',
            text: result.data.message
          }).then((result) => {
            _this.$router.push('/overview')
          })
        } else {
          if (result.data.message) {
            sweetAlert.fire({
              type: 'error',
              text: result.data.message
            })
          }
        }
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      validate() {
        const _this = this
        this.$validator.validateAll().then(isValid => {
          if (!isValid) return

          if (_this.form.newPassword != _this.form.comfirmNewPassword) {
            sweetAlert.fire({
              type: 'error',
              text: 'Two new passwords are not identical.'
            })
          } else {
            _this.updateMe()
          }
        })
      }
    },
    beforeDestroy () {
    }
  }
</script>
