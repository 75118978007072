<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
          <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
          <fade-render-transition>
            <card>
              <div slot="header"  style='text-align:center;'>

                <!-- <h3 class="card-title text-center">Login</h3> -->
                <!-- <img src='/static/img/xxx.png' style='width:150px'> -->
              </div>
              <div sytle='margin:0 auto'>
              
              </div>
              <div>
                <fg-input label="Account"
                          placeholder=""
                          name='email'
                          type='e-mail'
                          data-vv-as="Account"
                          v-validate="'required|email'"
                          :error="getError('email')"
                          v-model="form.email"
                          @keyup.enter="doLogin()">
                </fg-input>
                <fg-input label="Password"
                          type="password"
                          name="password"
                          data-vv-as="Password"
                          ref="password"
                          v-model="form.password"  
                          v-validate="'required'"     
                          :error="getError('password')"
                          @keyup.enter="doLogin()">
                </fg-input>
                <input type='text' name='recaptcha' v-model="form.recaptcha" v-validate="'required'" hidden/>
                <vue-recaptcha ref="recaptcha" :sitekey="sitekey" :loadRecaptchaScript="true" @verify="recaptchaVerify" @expired="recaptchaExpired" class='recaptcha' style='margin:0 auto;' ></vue-recaptcha>
                <div class='text-danger invalid-feedback' style="display:block" v-show="errors.has('recaptcha')">Plese click "I'm not a robot" checkbox</div>
              </div>
              <div class="text-center">
                <button type="submit" class="btn btn-fill btn-primary btn-round btn-wd mt-3" v-on:click='doLogin()'>Login</button>
                <div class="forgot">
                  <a href="#/forgetpassword" class="card-category">Forget Password?</a>
                </div>
              </div>
              
            </card>
          </fade-render-transition>
      </div>
    </div>
  </auth-layout>
</template>
<script>
  import { Checkbox as LCheckbox, FadeRenderTransition } from 'src/components/index'
  import AuthLayout from './AuthLayout.vue'
  import sweetAlert from 'sweetalert2/dist/sweetalert2.js'
  import lib from '../../../lib.js'
  import VueRecaptcha from 'vue-recaptcha'
  import Vue from "vue"

  export default {
    components: {
      LCheckbox,
      FadeRenderTransition,
      AuthLayout,
      VueRecaptcha
    },
 data () {
      return {
        sitekey: null,
        form: {
          email: '',
          password: '',
          recaptcha: ''
        },
        showAlert:false
      }
    },
    beforeMount (){
     this.sitekey = process.env.VUE_APP_RECAPTCHA_SITEKEY
    },
    methods: {
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      goForgetPassword(){
        this.$router.push('/forgetpassword')
      },
      doLogin(){
        const _this = this
        this.$validator.validateAll().then(async isValid => {
          if (!isValid) return;
          var result = await lib.login(this.form.email, this.form.password, this.form.recaptcha)
          if (result.status == Vue.Constants.HttpStatus.Success) {
            const user = result.data.data
            _this.$store.commit('set_user', JSON.stringify(user))
            _this.$router.push('/overview')
          } else {
            var msg = 'Wrong account or password'
            if (result.data.message) msg = result.data.message
            _this.form.password = ''
            _this.form.recaptcha = ''
            this.$refs.recaptcha.reset()
            sweetAlert.fire({
              type: 'error',
              text: msg
            })
          }
        })
      },
      toggleNavbar () {
        document.body.classList.toggle('nav-open')
      },
      closeMenu () {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      recaptchaVerify(token){
        this.form.recaptcha = token
      },
      recaptchaExpired(){
        this.form.recaptcha = ''
      },

    },
    beforeDestroy () {
      this.closeMenu()
    }
  }
</script>
<style>
  .navbar-nav .nav-item p{
    line-height: inherit;
    margin-left: 5px;
  }

  .recaptcha div{
    margin: 0 auto;
  }
</style>
