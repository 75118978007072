<template>
  <div>
    <card>
    <vue-tabs>
      <v-tab title='客戶服務' name='support'  v-if="checkScope(['support:r'])">
        <support/>
      </v-tab>
      <v-tab title='會員條款' name='policy' v-if="checkScope(['policy:r'])" >
        <policy/>
      </v-tab>
    </vue-tabs>
    </card>
  </div>
</template>
<script>
 /* eslint-disable */ 

  import support from '../Components/Support'
  import policy from '../Components/Policy'

  export default {
    components: {
      support: support,
      policy: policy
    }
  }
</script>
