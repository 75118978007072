<template>
  <div>

    <card class="px-3">
      <div class="row justify-content-between">
        <div style='font-size:20px'>Data Field Selection</div>
        <div class="d-flex">
          <!-- <l-button fill round size="sm" type="primary" @click="getList()" class="mr-2">Preview</l-button> -->
          <l-button fill round size="sm" type="primary" @click="download()">Download</l-button>
        </div>
      </div>
      <div class="row align-items-center mt-2">
        <div class="col-1">
          <b>Date</b>
        </div>
        <div class="ml-2">
          <el-date-picker
            v-model="query.dateRange"
            type="daterange"
            start-placeholder="Start"
            end-placeholder="End" />
        </div>
      </div>
      <div class="row align-items-center mt-2">
        <div class="col-1">
          <b>Disease</b>
        </div>
        <div class="ml-2">
          <el-select class="select-default" v-model="query.disease" clearable placeholder="Select">
            <el-option
              v-for="item in diseaseOptions"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <div class="row align-items-center mt-2">
        <div class="col-1">
          <b>Gender</b>
        </div>
        <div class="ml-2">
          <el-select class="select-default" v-model="query.gender" clearable placeholder="Select">
            <el-option
              v-for="item in genderOptions"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <div class="row align-items-center mt-2">
        <div class="col-1">
          <b>Age</b>
        </div>
        <div class="ml-2">
          <el-input-number v-model="query.age" :min="1" :max="150" />
        </div>
      </div>
    </card>

    <!-- <div class="row">
      <div class="col-sm-12">
        <el-table :data="generalInfoList" :cell-style="cellStyle">

          <el-table-column prop="sys_id" label="No">
          </el-table-column>

          <el-table-column prop="user_id" label="Case No">
          </el-table-column>

          <el-table-column prop="full_name" label="Name">
          </el-table-column>

          <el-table-column prop="age" label="Age">
          </el-table-column>

        </el-table>
      </div>
    </div>
    
    <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mt-3">
      <div class="">
        <p class="card-category"></p>
      </div>
      <l-pagination class="pagination-no-border"
        v-model="pagination.currentPage"
        :isMore="(PaginationToken != null)"
        :per-page="pagination.perPage"
        :total="pagination.total"
        @input='handleIndexChange'
        @loadMore="getData()"/>
    </div> -->
  </div>
</template>


<script>
 /* eslint-disable */ 

  import { Dialog, Table, TableColumn, MessageBox, Select, Option, DatePicker, Tag, Autocomplete } from 'element-ui'
  import { Pagination as LPagination, ImageUpload,  Switch as LSwitch } from 'src/components/index'
  import tools from '../../../tools'
  import swal from 'sweetalert2/dist/sweetalert2.js'
  import Vue from "vue"
  import GroupData from '../Components/GroupData'
  import GroupEdit from '../Components/GroupEdit'
  import GroupMemberAdd from '../Components/GroupMemberAdd'
  import Data from '../Components/Data'
  import lib from '../../../lib'


export default {
  data () {
    return {
      query: {
        age: 1,
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      PaginationToken: null,
      generalInfoList: null,
      targetData: null,
      diseaseOptions: [
        {
          value: 'Liver',
        },
        {
          value: 'Breast',
        },
        {
          value: 'Kidney',
        },
        {
          value: 'Heart',
        },
      ],
      genderOptions: [
        {
          value: 'Female',
        },
        {
          value: 'Male',
        },
      ]
    }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [Autocomplete.name]: Autocomplete,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Tag.name]: Tag,
    LSwitch,
    LPagination,
    ImageUpload,
    GroupData: GroupData,
    GroupEdit: GroupEdit,
    GroupMemberAdd: GroupMemberAdd,
    Data: Data
  },
  async mounted () {
  },
  // computed: {
  //   birthdayModel: {
  //       get: function () {
  //         return this.getBirthdayText(this.form.birthday)
  //       },
  //       set: function (newValue) {
  //         if (newValue) {
  //           this.form.birthday = newValue.getTime()
  //         } else {
  //           this.form.birthday = 0
  //         }
  //       }
  //     },
  // },
  methods: {
    cellStyle(row, rowIndex) {
      return 'cursor: pointer;'
    },
    async download() {
    },
    async getList() {
      var result = await lib.generalInfoList(this.pagination.currentPage - 1)
      if (result.status == Vue.Constants.HttpStatus.Success) {
        var data = result.data
        this.generalInfoList = data.data

        this.pagination.perPage = data.page_size
        this.pagination.currentPage=  data.page + 1
        this.pagination.total = data.total_size
      }
    },
    async handleIndexChange() {
      await this.getList()
    },
  }
}
</script>