<template>
  <div>

    <div v-show='isInGeneralInfoListMode()'>
      <div class='d-flex justify-content-between mb-5'>
        <div class='d-flex align-middle'>
          <fg-input
            class="my-auto ml-1 mr-2"
            v-model="query.hospital"
            placeholder="Case Number"
            type="text">
          </fg-input>
          <fg-input
            class="my-auto ml-1 mr-2"
            v-model="query.keyword"
            placeholder="Name"
            type="text">
          </fg-input>
          <l-button fill round size="sm" type="primary" @click="search()" class="m-auto">Search</l-button>
        </div>
      </div>
      <div class="d-flex justify-content-end mb-2">
        <button type="button" class="btn btn-round btn-large btn-primary my-auto" v-b-modal.add-basic-modal>Add</button>
      </div>
      <div>
        <b-modal id="add-basic-modal" centered title="Add Dataset" ok-title="Save">
          <fg-input label="Case Number"
                    name="caseNumber"/>
          <fg-input label="ID Number"
                    name="idNumber"/>
          <fg-input label="Name"
                    name="name"/>
          <fg-input label="Birthday"
                    name="birthday"
                    placeholder="1999/01/01"/>
          <fg-input label="Gender"
                    name="gender"/>
        </b-modal>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <el-table :data="generalInfoList" @row-click="handleRowChange" :cell-style="cellStyle">

            <el-table-column prop="sys_id" label="No">
            </el-table-column>

            <el-table-column prop="user_id" label="Case No">
            </el-table-column>

            <el-table-column prop="full_name" label="Name">
            </el-table-column>

            <el-table-column prop="age" label="Age">
            </el-table-column>

            <!-- <el-table-column prop="funciton" label="" width="64px" class="mx-0 px-0">
              <l-button slot-scope="props" outline size="sm" type="info" @click="view(props.row)" class="m-auto px-2">view</l-button>
            </el-table-column>

            <el-table-column prop="funciton" label="" width="60px" class="mx-0">
              <l-button slot-scope="props" outline size="sm" type="info" @click="edit(props.row)" class="m-auto px-2">edit</l-button>
            </el-table-column> -->

            <!-- <el-table-column prop="status" :formatter="getStatusText" label="Status">
            </el-table-column> -->

          </el-table>
        </div>
      </div>
      
      <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mt-3">
        <div class="">
          <p class="card-category"></p>
        </div>
        <l-pagination class="pagination-no-border"
          v-model="pagination.currentPage"
          :isMore="(PaginationToken != null)"
          :per-page="pagination.perPage"
          :total="pagination.total"
          @input='handleIndexChange'
          @loadMore="getData()"/>
      </div>
    </div>

    <Data :data='targetData' v-if='isInDataMode()' @back="back()" />
  </div>
</template>


<script>
 /* eslint-disable */ 

  import { Dialog, Table, TableColumn, MessageBox, Select, Option, DatePicker, Tag, Autocomplete } from 'element-ui'
  import { Pagination as LPagination, ImageUpload,  Switch as LSwitch } from 'src/components/index'
  import { BModal, VBModal } from 'bootstrap-vue'
  import tools from '../../../tools'
  import swal from 'sweetalert2/dist/sweetalert2.js'
  import Vue from "vue"
  import GroupData from '../Components/GroupData'
  import GroupEdit from '../Components/GroupEdit'
  import GroupMemberAdd from '../Components/GroupMemberAdd'
  import Data from '../Components/Data'
  import lib from '../../../lib'


export default {
  data () {
    return {
      query: {},
      mode: Vue.Constants.Dataset.GeneralInfoList,
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      PaginationToken: null,
      generalInfoList: null,
      targetData: null
    }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [Autocomplete.name]: Autocomplete,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Tag.name]: Tag,
    LSwitch,
    LPagination,
    ImageUpload,
    BModal,
    GroupData: GroupData,
    GroupEdit: GroupEdit,
    GroupMemberAdd: GroupMemberAdd,
    Data: Data
  },
  directives: {
    'b-modal': VBModal
  },
  async mounted () {
    await this.getList()
  },
  methods: {
    cellStyle(row, rowIndex) {
      return 'cursor: pointer;'
    },
    search() {

    },
    save() {
      $('#addModal').modal('hide')
    },
    async getList() {
      var result = await lib.generalInfoList(this.pagination.currentPage - 1)
      if (result.status == Vue.Constants.HttpStatus.Success) {
        var data = result.data
        this.generalInfoList = data.data

        this.pagination.perPage = data.page_size
        this.pagination.currentPage=  data.page + 1
        this.pagination.total = data.total_size
      }
    },
    isInGeneralInfoListMode() {
      return this.mode == Vue.Constants.Dataset.GeneralInfoList
    },
    isInDataMode() {
      return this.mode == Vue.Constants.Dataset.Data
    },
    back() {
      this.mode = Vue.Constants.Dataset.GeneralInfoList
      this.targetData = null
    },
    edit(info) {
        console.log('edit', info)
    },
    view(info) {
      this.targetData = info
      this.mode = Vue.Constants.Dataset.Data
    },
    handleRowChange(row, event, column) {
      this.targetData = row
      this.mode = Vue.Constants.Dataset.Data
    },
    async handleIndexChange() {
      await this.getList()
    },
  }
}
</script>

<style>
addModal.in{
    z-index: 999999;
}
</style>