<template>
  <div>
    <card>
    <vue-tabs>
      <v-tab title='活動資訊' name='activiy' >
        <activiy/>
      </v-tab>
    </vue-tabs>
    </card>
  </div>
</template>
<script>
 /* eslint-disable */ 

  import activiy from '../Components/Activity'

  export default {
    components: {
      activiy: activiy
    }
  }
</script>
