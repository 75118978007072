<template>
  <div>
    <div class='row mb-3'>
      <div class="col-md-12">
        <div class="float-right">
          <button type="button" @click="edit()" class="btn btn-primary btn-primary" v-show='!isEditing'>Edit</button>
          <button type="submit" @click.prevent="validate" class="btn btn-fill btn-primary ml-3" v-show='isEditing'>Save</button>
          <button type="button" @click="cancel()" class="btn btn-outline ml-3" v-show='isEditing'>Cancel</button>
        </div>
      </div>
    </div>

    <div class="row" v-if='me'>
      <div class="col-md" v-show='!isEditing'>
        <card>
          <h3>Personal Document</h3>
          <hr/>
          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Display Name</b></p>
            </div>
            <div :class="valueColumnClass">
              <p>{{me.displayName}}</p>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Identity Card</b></p>
            </div>
            <div :class="valueColumnClass">
              <p>{{me.identity}}</p>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Contact Phone</b></p>
            </div>
            <div :class="valueColumnClass">
              <p>{{me.mobile}}</p>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Email</b></p>
            </div>
            <div :class="valueColumnClass">
              <p>{{me.email}}</p>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>First Name</b></p>
            </div>
            <div :class="valueColumnClass">
              <p>{{me.firstName}}</p>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Last Name</b></p>
            </div>
            <div :class="valueColumnClass">
              <p>{{me.lastName}}</p>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Hospital</b></p>
            </div>
            <div :class="valueColumnClass">
              <p>{{me.hospital}}</p>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Title</b></p>
            </div>
            <div :class="valueColumnClass">
              <p>{{me.title}}</p>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Department</b></p>
            </div>
            <div :class="valueColumnClass">
              <p>{{me.department}}</p>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Specialist</b></p>
            </div>
            <div :class="valueColumnClass">
              <p>{{me.specialists}}</p>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Phone Number</b></p>
            </div>
            <div :class="valueColumnClass">
              <p>{{me.phone}}</p>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Gender</b></p>
            </div>
            <div :class="valueColumnClass">
              <p>{{getGenderText(me.gender)}}</p>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Birthday</b></p>
            </div>
            <div :class="valueColumnClass">
              <p>{{getBirthdayText(me.birthday)}}</p>
            </div>
          </div>
        </card>
      </div>

      <div class="col-md" v-if='form' v-show='isEditing'>
        <card>
          <h3>Personal Document</h3>
          <hr/>
          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Display Name</b></p>
            </div>
            <div :class="valueColumnClass">
              <p>{{form.displayName}}</p>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Identity Card</b></p>
            </div>
            <div :class="valueColumnClass">
              <p>{{form.identity}}</p>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Contact Phone</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                type="text"
                name="mobile"
                data-vv-as="Contact Phone"
                class=''
                v-model="form.mobile"
                :error="getError('mobile')"/>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Email</b></p>
            </div>
            <div :class="valueColumnClass">
              <p>{{form.email}}</p>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>First Name</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                type="text"
                name="firstName"
                data-vv-as="First Name"
                class=''
                v-model="form.firstName"
                :error="getError('firstName')"/>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Last Name</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                type="text"
                name="lastName"
                data-vv-as="Last Name"
                class=''
                v-model="form.lastName"
                :error="getError('lastName')"/>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Hospital</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                type="text"
                name="hostipal"
                data-vv-as="Hospital"
                class=''
                v-model="form.hospital"
                :error="getError('hostipal')"/>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Title</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                type="text"
                name="title"
                data-vv-as="Title"
                class=''
                v-model="form.title"
                :error="getError('title')"/>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Department</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                type="text"
                name="department"
                data-vv-as="Department"
                class=''
                v-model="form.department"
                :error="getError('department')"/>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Specialist</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                type="text"
                name="specialists"
                data-vv-as="Specialist"
                class=''
                v-model="form.specialists"
                :error="getError('specialists')"/>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Phone Number</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                type="text"
                name="phone"
                data-vv-as="Phone Number"
                class=''
                v-model="form.phone"
                :error="getError('phone')"/>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Gender</b></p>
            </div>
            <div :class="valueColumnClass">
              <el-select class="select-default mb-3" v-model="genderModel" :close-transition="true">
                <el-option value='0' label="Unknown" />
                <el-option value='1' label="Male" />
                <el-option value='2' label="Female" />
              </el-select>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Birthday</b></p>
            </div>
            <div :class="valueColumnClass">
              <el-date-picker
                class="mb-3"
                v-model="birthdayModel"
                type="date"
                placeholder="Pick a day"
                format="yyyy/MM/dd">
              </el-date-picker>
            </div>
          </div>
        </card>
      </div>

      <div class="col-md">
        <card>
          <h3>Groups Information</h3>
          <hr/>
          <div class="row" v-if='adminGroups.length != 0'>
            <div class="text-right" :class="titleColumnClass">
              <p><b>Admin</b></p>
            </div>
            <div :class="valueColumnClass">
              <p v-for="item in adminGroups" :key="item.key">
                {{ item.name }}
              </p>
            </div>
          </div>
          <br v-if='adminGroups.length != 0'/>
          <div class="row" v-if='memberGroups.length != 0'>
            <div class="text-right" :class="titleColumnClass">
              <p><b>Member</b></p>
            </div>
            <div :class="valueColumnClass">
              <p v-for="item in memberGroups" :key="item.key">
                {{ item.name }}
              </p>
            </div>
          </div>
        </card>
      </div>
    </div>

    
    
  </div>
</template>
<script>
  import { Dialog, Table, TableColumn, MessageBox, Select, Option, DatePicker, Tag, Autocomplete } from 'element-ui'
  import { Checkbox as LCheckbox, FadeRenderTransition } from 'src/components/index'
  import Card from '../../../components/Cards/Card.vue'
  import sweetAlert from 'sweetalert2/dist/sweetalert2.js'
  import lib from '../../../lib.js'
  import Vue from "vue"

  export default {
    components: {
      [DatePicker.name]: DatePicker,
      [Select.name]: Select,
      [Option.name]: Option,
      LCheckbox,
      FadeRenderTransition
    },
    Card,
    data () {
      return {
        titleColumnClass: 'col-md-3',
        valueColumnClass: 'col-md-9',
        me: null,
        form: null,
        showAlert: false,
        isEditing: false,
        adminGroups: [],
        memberGroups: []
      }
    },
    beforeMount (){
    },
    async mounted () {
      await this.getMe()
    },
    computed: {
      genderModel: {
        get: function() {
            return this.getGenderText(this.form.gender)
        },
        set: function(newValue) {
            switch (newValue) {
              case '0':
                this.form.gender = 0
                break
              case '1':
                this.form.gender = 1
                break
              case '2':
                this.form.gender = 2
                break
              default:
                break
            }
        }
      },
      birthdayModel: {
        get: function () {
            return this.getBirthdayText(this.form.birthday)
        },
        set: function (newValue) {
            if (newValue) {
              this.form.birthday = newValue.getTime()
            } else {
              this.form.birthday = 0
            }
        }
      }
    },
    methods: {
      async getMe() {
        const result = await lib.getMe()
        if (result.status == Vue.Constants.HttpStatus.Success) {
          const me = result.data.data
          this.me = me

          this.adminGroups = []
          this.memberGroups = []
          for (let group of me.groups) {
            if (group.admin == me.id) {
              this.adminGroups.push(group)
            } else {
              this.memberGroups.push(group)
            }
          }
        } else {
          if (result.data.message) {
            sweetAlert.fire({
              type: 'error',
              text: result.data.message
            })
          }
        }
      },
      async updateMe() {
        console.log('this.form', this.form)
        const result = await lib.updateMe(this.form)
        if (result.status == Vue.Constants.HttpStatus.Success) {
          // const newMe = result.data.data
          
          // let key
          // for (key in newMe) {
          //   this.me[key] = newMe[key]
          // }

          let key
          for (key in this.form) {
            this.me[key] = this.form[key]
          }

          this.isEditing = false
        } else {
          if (result.data.message) {
            sweetAlert.fire({
              type: 'error',
              text: result.data.message
            })
          }
        }
      },
      getGenderText(value) {
        switch (value) {
          case 0:
            return "Unknown"
          case 1:
            return "Male"
          case 2:
            return "Female"
          default:
            return ""
        }
      },
      getBirthdayText(value) {
        const date = new Date(value)
        return this.formatDate(date)
      },
      formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('/');
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      edit() {
        this.createForm()
        this.isEditing = true
      }, 
      cancel() {
        this.isEditing = false
      },
      validate() {
        var _this = this
        this.$validator.validateAll().then(isValid => {
          if (!isValid) return
          
          _this.updateMe()
        })
      },
      createForm() {
        this.form = JSON.parse(JSON.stringify(this.me))
      }
    },
    beforeDestroy () {
    }
  }
</script>
