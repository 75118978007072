<template>
  <div>
    <card>
    <vue-tabs @tab-change="handleTabChange" ref='tabs'>
      <v-tab title='後台管理者' name='user'  v-if="checkScope(['user:r'])" >
        <user ref="user" v-if="currentPage == 'user'" />
      </v-tab>
      <v-tab title='分公司/部門設定' name='organization' v-if="checkScope(['organization:r'])" >
        <company ref="company" v-if="currentPage == 'organization'" />
      </v-tab>
      <v-tab title='權限設定' name='permission' v-if="checkScope(['permission:r'])" >
        <permission v-if="currentPage == 'permission'" />
      </v-tab>
    </vue-tabs>
    </card>
  </div>
</template>
<script>
 /* eslint-disable */ 

  import user from '../Components/User'
  import company from '../Components/Company'
  import permission from '../Components/Permission'
  import lib from '../../../lib'

  export default {
    data () {
      return {
        currentPage : 'user'
      }
    },
    components: {
      user: user,
      company: company,
      permission: permission
    },
    mounted(){
      if (this.checkScope(['user:r'])) {
        this.currentPage = 'user'
      }else if (this.checkScope(['organization:r'])){
         this.currentPage = 'organization'
      }else if(this.checkScope(['permission:r'])){
        this.currentPage = 'permission'
      }
    },
    methods:{
      handleTabChange(tabIndex, newTab, oldTab){
        let newTabName = newTab.$attrs.name
        this.currentPage = newTabName
      }
    }
    
  }
</script>
